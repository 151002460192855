import React, {useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import DesktopMenu from "../components/DesktopMenu";
import {Button, Container, Grid, TextField, Typography} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from "@material-ui/core/styles";

import BackgroundImage from '../assets/background.jpg';
import Footer from "../components/Footer";
import TrackingQueryDialog from "../components/TrackingQueryDialog";
import {useDispatch} from "react-redux";
import {openTrackingQueryDialog} from "../actions/dialogs";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainContainer        : {
        height         : '100%',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize : 'cover',
        paddingLeft    : 0,
        paddingRight   : 0
    },
    mainDiv              : {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        marginTop      : '15vh',
        marginBottom   : '10vh',
        width          : '70%',
        padding        : '2em',
        borderRadius   : '5px'
    },
    input                : {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: '2px'
            },
            '& input'   : {
                borderRadius   : '5px',
                backgroundColor: '#FFFFFF',
                color          : '#000000'
            }
        },
        marginBottom              : '2em',
        width                     : '70%'
    },
    button               : {
        color     : '#FFFFFF',
        background: `linear-gradient(90deg, rgba(250,91,84,1) 0%, rgba(252,133,63,1) 100%)`,
    },
    questionsButton      : {
        width          : '20%',
        backgroundColor: `rgb(0, 0, 0, 0.4)`,
        borderRadius   : '25px',
        borderColor    : '#FFFFFF',
        borderWidth    : '1px',
        borderStyle    : 'solid',
        color          : '#FFFFFF',
        textTransform  : 'none'
    },
    questionsButtonMobile: {
        width          : '80%',
        backgroundColor: `rgb(0, 0, 0, 0.4)`,
        borderRadius   : '25px',
        borderColor    : '#FFFFFF',
        borderWidth    : '1px',
        borderStyle    : 'solid',
        color          : '#FFFFFF',
        textTransform  : 'none'
    },
    covidButton          : {
        width          : '50%',
        backgroundColor: `rgba(255, 255, 255, 0.4)`,
        borderRadius   : '25px',
        color          : '#FFFFFF',
        textTransform  : 'none',
        padding        : '0.1 rem'
    },
    covidButtonMobile    : {
        width          : '80%',
        backgroundColor: `rgba(255, 255, 255, 0.4)`,
        borderRadius   : '25px',
        color          : '#FFFFFF',
        textTransform  : 'none',
        padding        : '0.1 rem'
    },
    welcomeTextMobile    : {}
}));


export default (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [trackingNumber, setTrackingNumber] = useState(0);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleSearchButton = () => {

        if (!trackingNumber) {
            alert('Ingrese un número de seguimiento válido');
            return;
        }

        props.history.push(`/${trackingNumber}`);
    };

    const handleOpenTrackingQueryDialog = () => {
        dispatch(openTrackingQueryDialog())
    }

    return (
        <>
            <CssBaseline/>

            <TrackingQueryDialog/>

            <Container maxWidth={false} classes={{
                root: classes.mainContainer
            }}>
                <AppBar position={"static"} color={"primary"}>
                    <DesktopMenu/>
                </AppBar>
                <Grid container>
                    <Grid item xs={12} align={"center"}>

                        <div>
                            <form className={classes.mainDiv} onSubmit={handleSearchButton}>
                                <Typography variant={"h2"}
                                            classes={{
                                                root: isMobile ? classes.welcomeTextMobile : ''
                                            }}
                                            style={{
                                                color       : 'white',
                                                marginBottom: '0.5em',
                                                fontSize    : isMobile ? '1.75rem' : '3.75rem'
                                            }}>
                                    ¿Querés seguir tu envío?
                                </Typography>
                                <Typography variant={"subtitle1"} style={{
                                    color       : `rgb(255, 255, 255, 0.6)`,
                                    marginBottom: '2em'
                                }}>
                                    Ingresá tu número de orden y enterate del estado de tu
                                    paquete en un instante.
                                </Typography>
                                <TextField
                                    variant={"outlined"}
                                    classes={{
                                        root: classes.input
                                    }}
                                    onChange={(e) => setTrackingNumber(e.target.value)}
                                />
                                <br/>
                                <Button
                                    startIcon={<SearchIcon/>}
                                    color={"primary"}
                                    variant={"contained"}
                                    size={"large"}
                                    classes={{
                                        root: classes.button
                                    }}
                                    onClick={handleSearchButton}
                                >
                                    Buscar
                                </Button>

                            </form>

                            <div style={{
                                marginBottom: '10vh'
                            }}>
                                <Button
                                    classes={{
                                        root: isMobile ? classes.questionsButtonMobile : classes.questionsButton
                                    }}
                                    onClick={handleOpenTrackingQueryDialog}
                                >
                                    ¿Alguna consulta sobre tu envío?
                                </Button>
                            </div>

                            <div>
                                <Button
                                    classes={{
                                        root: isMobile ? classes.covidButtonMobile : classes.covidButton
                                    }}
                                    startIcon={<WarningIcon/>}
                                    onClick={() => {
                                        window.location.href = 'http://www.atenelogistic.com/wp-content/uploads/2020/05/OPERATIVA-CONTINUA-Y-PROTOCOLAR.pdf'
                                    }}
                                >
                                    !Conocé nuestras medidas de seguiridad para el COVID-19!
                                </Button>
                            </div>
                        </div>

                        <Footer/>

                    </Grid>
                </Grid>
            </Container>
        </>
    );

}
